<template>
  <div id="box-feed">
    <div class="box">
      <h2>Upcoming Shows</h2>
      <div v-for="show in shows" :key="show.date" class="show-table">
        <div class="date">{{ show.date }}</div>
        <div class="venue">{{ show.venue }}</div>
        <div class="location">{{ show.location }}</div>
        <div class="link">
          <a v-if="show.link" :href="show.link">more info</a>
        </div>
      </div>
    </div>
    <div class="box">
      <h2>Past Shows</h2>
      <div v-for="show in priorShows" :key="show.date" class="show-table">
        <div class="date">{{ show.date }}</div>
        <div class="venue">{{ show.venue }}</div>
        <div class="location">{{ show.location }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowsView",
  components: {},
  data() {
    return {
      shows: [
        {
          date: "28-Feb-2025",
          venue: "The Facebar",
          location: "Reading",
        },
      ],
      priorShows: [
        {
          date: "20-Dec-2024",
          venue: "The Facebar",
          location: "Reading",
        },
        {
          date: "06-Nov-2024",
          venue: "Purple Turtle",
          location: "Reading",
        },
        {
          date: "23-Aug-2024",
          venue: "Nestival, The Bird's Nest",
          location: "Deptford",
        },
        {
          date: "29-Jul-2024",
          venue: "Purple Turtle",
          location: "Reading",
        },
        {
          date: "21-Jul-2024",
          venue: "Fiddler's Elbow",
          location: "Camden",
          link: "https://www.facebook.com/events/1097542481580666",
        },
        {
          date: "7-Jun-2024",
          venue: "The Butler",
          location: "Reading",
        },
        {
          date: "10-May-2024",
          venue: "Hippos",
          location: "Swansea",
          link: "https://www.facebook.com/events/1097542481580666",
        },
        {
          date: "19-Apr-2024",
          venue: "The Old Bus Depot",
          location: "Nottingham",
        },
        {
          date: "24-Feb-2024",
          venue: "The Butler",
          location: "Reading",
        },
        {
          date: "29-Jan-2024",
          venue: "Purple Turtle",
          location: "Reading",
        },
        {
          date: "10-Nov-2023",
          venue: "The Facebar",
          location: "Reading",
        },
      ],
    };
  },
};
</script>
